import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import TableDates from "./Tables/TableDates";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { hotjar } from 'react-hotjar'
import { useNavigate } from "react-router-dom";
import providersList from "../../utils/providersList";

import TablesHeader from "../../components/TablesHeader";

const api = axios.create({
  baseURL: "https://www.google.com/recaptcha/api/siteverify",
});

const pages = ["dates", "other_revenues", "expenses", "reports"];

export default function Rates() {
  const { provider } = useParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(pages[0]);
  const [previousPage, setPreviousPage] = useState([]);
  let [currentSavedData, setCurrentSavedData] = useState({});

  let navigate = useNavigate();

  useEffect(() => {
    providersList.includes(provider) ? console.log("Provider is okay") : navigate('/');
  }, []);

  const handleError = (errMsg) => {
    toast.error(errMsg);
  };

  const verifyDataBeforeChangePage = (obj) => {
    const keys = Object.keys(obj);
    const currentError = [];
    keys.forEach((key) => {
      obj[key].forEach((itemEach) => {
        if (!itemEach || itemEach === `NaN`) {
          currentError.push(itemEach);
        }
      });
    });

    return currentError.length > 0;
  };

  const nextPage = async (item) => {
    const currentPageIndex = pages.indexOf(currentPage);

    if (currentPageIndex <= -1 && currentPageIndex > pages.length) {
      throw new Error("Invalid page");
    }
    if (verifyDataBeforeChangePage(item)) {
      toast.error("All fields need to be valid and required.");
    } else {
      const previousPageTmp = previousPage;
      previousPageTmp.push(currentPage);
      setPreviousPage(previousPageTmp);
      setCurrentSavedData(Object.assign({ ...currentSavedData, ...item }));
      setCurrentPage(pages[pages.indexOf(currentPage) + 1]);
    }
  };

  const backPage = (item) => {
    if (verifyDataBeforeChangePage(item)) {
      setCurrentSavedData(Object.assign({ ...currentSavedData, ...item }));
    }
    const lastPage = previousPage[previousPage.length - 1];
    setCurrentPage(lastPage);
    let newPreviousArr = previousPage;
    newPreviousArr.pop();
    setPreviousPage(newPreviousArr);
  };

  useEffect(() => {
  }, [currentSavedData]);

  // useEffect(() => {
  //   hotjar.initialize('3315490', 6);
  // }, [])

  return (
    <>
      <ToastContainer />
      <RatesContainer>
        <TablesHeader />
        <MainSection>
          <Watermark />
          <ContainerMain>
            <TableHeader>
              <h1>Bezla Rates</h1>
              <span>
                Please select a date range that you would like to search and perform the comparison.
              </span>
            </TableHeader>
            <TableContainer>
              {currentPage === "dates" && (
                <TableDates
                  nextPage={(item) => nextPage(item)}
                  backPage={(item) => backPage(item)}
                  handleError={(item) => handleError(item)}
                />
              )}
            </TableContainer>
          </ContainerMain>
        </MainSection>
      </RatesContainer>
    </>
  );
}

const Watermark = styled.div`
  position: fixed;
  width: 90%;
  height: 90%;
  margin: auto;
  z-index: -1;
  background-size: 30%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/watermark_bezla.png");
  opacity: 0.15;
`;

const RatesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BackgroundHeader = styled.header`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #333333;
  width: 100%;
  z-index: 1000;
`;

const Container = styled.div`
  display: flex;
  align-self: center;
  max-width: 1212px;
`;

const ContainerHeader = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  padding: 0 2rem;
  width: 100%;

  & > a {
    & > img {
      width: 4rem;
      height: auto;
    }
  }
`;

const ContainerMain = styled(Container)`
  margin-top: 8.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  color: white;

  & > a {
    font-weight: 400;
    padding: 0.5rem;
    margin-right: 1rem;
    font-size: 1.2rem;
    text-decoration: none;
    color: white;

    &:last-of-type {
      margin-right: 0;
    }
  }
  @media (max-width: 890px) {
    display: none;
  }
`;

const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableHeader = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;

  & > h1 {
    color: #ab8585;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    font-family: "Playfair Display", serif;
  }

  & > span {
    font-family: "Open Sans", serif;
    line-height: 1.375rem;
    margin-top: 0.5rem;
    color: #575757;
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const BurguerMenu = styled.nav`
  @media (min-width: 889px) {
    display: none;
  }
  & > button {
    padding: 0.25rem 0.75rem;
    background-color: transparent;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
    & > img {
      width: 1.5rem;
      height: 1.5rem;
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(209deg)
        brightness(100%) contrast(102%);
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(22, 22, 22, 0.9);
  top: 0;
  left: 0;
  z-index: 2;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  color: #f7f7f7;

  & > div {
    padding: 0.625rem 0.625rem 0.625rem 2.8125rem;
    border-bottom: 1px solid rgba(76, 76, 76, 0.5);
    &:first-child {
      border-bottom: none;
      display: flex;
      flex-direction: row-reverse;
      align-content: flex-end;

      & > button {
        padding: 0.625rem 2.8125rem 0.625rem 2.8125rem;
        background-color: transparent;
        border-style: solid;
        border-color: transparent;
        cursor: pointer;
        & > img {
          width: 1.5rem;
          height: 1.5rem;
          filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(209deg)
            brightness(100%) contrast(102%);
        }
      }
    }

    & > a {
      line-height: 1.6;
      font-size: 2rem;
      color: #f7f7f7;
      text-decoration: none;
      font-family: "Open Sans", sans-serif;
    }
  }
`;
