import React, { useState } from "react";
import styled from "styled-components";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <HeaderComponent>
      <Overlay isOpen={isMenuOpen}>
        <div>
          <button onClick={() => setIsMenuOpen(false)}>
            <img src="/close.svg" alt="Close button" />
          </button>
        </div>
        <div>
          <a href="https://bezla.com/services">Services</a>
        </div>
        <div>
          <a href="https://bezla.com/products">Products</a>
        </div>
        <div>
          <a href="https://careers.bezla.com/jobs/Careers">Careers</a>
        </div>
        <div>
          <a href="https://bezla.com/about">About</a>
        </div>
        <div>
          <a href="https://bezla.com/team">Team</a>
        </div>
        <div>
          <a href="https://bezla.com/blog">Blog</a>
        </div>
        <div>
          <a href="https://training.bezla.com/login">Sign In</a>
        </div>
        <div>
          <a href="https://bezla.com/contact">Contact</a>
        </div>
      </Overlay>
      <BurguerMenu>
        <button onClick={() => setIsMenuOpen(true)}>
          <img src="/bgr-menu.svg" alt="Menu Icon" />
        </button>
      </BurguerMenu>
      <ImgContainer>
        <a href="https://bezla.com/">
          <img src="/logo.png" alt="Bezla Logo" />
        </a>
      </ImgContainer>
      <Nav className="flex flex-row">
        <LinkNav href="https://bezla.com/services" label="SERVICES" />
        <LinkNav href="https://bezla.com/products" label="PRODUCTS" />
        <LinkNav
          href="https://careers.bezla.com/jobs/Careers"
          label="CAREERS"
        />
        <LinkNav href="https://bezla.com/about" label="ABOUT" />
        <LinkNav href="https://bezla.com/team" label="TEAM" />
        <LinkNav href="https://bezla.com/blog" label="BLOG" />
        <LinkNav href="https://training.bezla.com/login" label="SIGN IN" />
        <LinkNav href="https://bezla.com/contact" label="CONTACT" />
      </Nav>
    </HeaderComponent>
  );
}

const HeaderComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.25rem 0;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;

  @media (max-width: 1210px) {
    display: none;
  }
`;

const LinkNav = ({ selected, label, href }) => {
  return (
    <LinkComponent
      selected={selected}
      style={{ letterSpacing: ".071em" }}
      href={href}
    >
      {label}
    </LinkComponent>
  );
};

const LinkComponent = styled.a`
  padding: 0.5rem;
  font-size: 1.125rem;
  margin-right: 1rem;
  letter-spacing: 0.071em;
  color: white;
  font-weight: ${(props) => (props.selected ? "700" : "400")};
  text-decoration: none;
  white-space: nowrap;
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 1210px) {
    justify-content: center;

    & > a {
      transform: translateX(-1.2rem);
      height: 5rem;
      width: 5rem;
    }
  }
`;

const BurguerMenu = styled.nav`
  @media (min-width: 1210px) {
    display: none;
  }
  & > button {
    padding: 0.25rem 0.75rem;
    background-color: transparent;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
    & > img {
      width: 1.5rem;
      height: 1.5rem;
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(209deg)
        brightness(100%) contrast(102%);
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: rgba(22, 22, 22, 0.9);
  top: 0;
  left: 0;
  z-index: 2;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  color: #f7f7f7;

  & > div {
    padding: 0.625rem 0.625rem 0.625rem 2.8125rem;
    border-bottom: 1px solid rgba(76, 76, 76, 0.5);
    &:first-child {
      border-bottom: none;
      display: flex;
      flex-direction: row-reverse;
      align-content: flex-end;

      & > button {
        padding: 0.625rem 2.8125rem 0.625rem 2.8125rem;
        background-color: transparent;
        border-style: solid;
        border-color: transparent;
        cursor: pointer;
        & > img {
          width: 1.5rem;
          height: 1.5rem;
          filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(209deg)
            brightness(100%) contrast(102%);
        }
      }
    }

    & > a {
      line-height: 1.6;
      font-size: 2rem;
      color: #f7f7f7;
      text-decoration: none;
      font-family: "Open Sans", sans-serif;
    }
  }
`;
