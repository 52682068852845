import React from "react";
import styled from "styled-components";

export default function Input({ placeholder, onChange, label, variant , value}) {
    return (
        <InputComponent
            onChange={onChange}
            style={styles[variant] || styles["primary"]}
            value={value}
            placeholder={placeholder}
            {...variant}
        />
    );
}

const styles = {
    primary: {
        backgroundColor: "#575757",
        color: "#FFF",
        border: "none",
        hoverBackgroundColor: "#6D6D6D",
    },
    secondary: {
        backgroundColor: "#FFF",
        color: "#575757",
        border: "1px solid #E6E6E6",
        hoverBackgroundColor: "#FFF",
    },
};

const InputComponent = styled.input`
  background-color: ${(props) => props.style.backgroundColor};
  color: ${(props) => props.style.color};
  border: ${(props) => props.style.border};
  border-radius: 5px;
  width: 40rem;
  font-size: 1.25rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 2.5rem;
  text-align: left;
  box-shadow: 0px 4px 17px -2px rgba(0, 0, 0, 0.08);
  padding: 0.25rem 10px;
  &:hover {
    background-color: ${(props) => props.style.hoverBackgroundColor};
    transition: 2s;
  }
`;
