import React, { useState } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import getCounter from "../../providers/GetCounter";
import updateCounter from "../../providers/UpdateCounter";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../components/Footer";
import providersList from "../../utils/providersList";

export default function Home() {
  const [captchaValue, setCaptchaValue] = useState(null);

  let navigate = useNavigate();

  const onSubmitButton = async () => {
    if (captchaValue) {
      const res = await verifyCaptcha(captchaValue);
      if (res) {
        let counter = await getCounter() || 0;
        counter++
        updateCounter(counter);
        navigate(`/rates/${providersList[counter % providersList.length]}`);
      } else {
        toast.warn("Please revalidate the reCAPTCHA");
      }
    } else {
      toast.warn("Please fill the reCAPTCHA.");
    }
  };

  const verifyCaptcha = async (id) => {
    let requestURL = window?.location.href.includes("localhost")
      ? "http://localhost:5001/rates-bb81c/us-central1/getCaptcha"
      : "https://us-central1-budget-effc0.cloudfunctions.net/getCaptchaBudget";

    try {
      const { data } = await axios.post(requestURL, {
        token: id,
      });

      return data.success ? true : data === "OK" ? true : false;
    } catch (error) {
      return false;
    }
  };

  return (
    <Container>
      <MainSection>
        <ToastContainer />
        <Header />
        <TitleSection>
          <h1>Rates</h1>
          <span>
            Make more intelligent hotel pricing decisions. Get real-time rate comparison data with the <br />
            industry-leading hotel rate shopper from Bezla.
          </span>
          <ReCAPTCHA
            sitekey="6Ld_S8MdAAAAACAkzMl0Pfsc3eMUDGXWjv2C_lgT"
            onChange={(item) => setCaptchaValue(item)}
            onErrored={() => setCaptchaValue(null)}
          />
        </TitleSection>
        <ButtonSection>
          <button onClick={() => onSubmitButton()} type="button">
            START RATES
          </button>
        </ButtonSection>
      </MainSection>
      <FooterSection>
        <Footer />
      </FooterSection>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainSection = styled.div`
  padding: 0 2rem;
  background: #343a40;
`;

const FooterSection = styled.div`
  padding: 0 2rem;
  background: rgba(22,22,22,1);
`;

const TitleSection = styled.section`
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 6rem;
  & > h1 {
    text-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
    font-weight: 700;
    line-height: 1.3;
    font-size: 4.5rem;
    font-family: "Open Sans", sans-serif;
  }

  & > span {
    text-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
    text-align: center;
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 1rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: "Open Sans", sans-serif;
  }
`;

const ButtonSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 10rem;

  & > button {
    background-color: white;
    padding: 0.8rem 3rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 4px;
    margin-top: 2rem;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
    font-family: "Open Sans", serif;
  }

  & > button:hover {
    background-color: rgb(230, 230, 230);;
  }
`;
