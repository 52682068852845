import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// CSS Style
import "./styles.css";

export default function TablesHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="navbar sticky-top navbar-expand-lg navbar-color">
      <div className="container">
        <Overlay isOpen={isMenuOpen}>
          <div>
            <button onClick={() => setIsMenuOpen(false)}>
              <img src="/close.svg" alt="Close button" />
            </button>
          </div>
          <div className="nav-item">
            <Link className="nav-link" to="https://bezla.com/services">
              Services
            </Link>
          </div>
          <div className="nav-item">
            <Link className="nav-link" to="https://bezla.com/products">
              Products
            </Link>
          </div>
          <div className="nav-item">
            <Link
              className="nav-link"
              to="https://careers.bezla.com/jobs/Careers"
            >
              Careers
            </Link>
          </div>
          <div className="nav-item">
            <Link className="nav-link" to="https://bezla.com/about">
              About
            </Link>
          </div>
          <div className="nav-item">
            <Link className="nav-link" to="https://bezla.com/team">
              Team
            </Link>
          </div>
          <div className="nav-item">
            <Link className="nav-link" to="https://bezla.com/blog">
              Blog
            </Link>
          </div>
          <div className="nav-item">
            <Link className="nav-link" to="https://training.bezla.com/login">
              Sign In
            </Link>
          </div>
          <div className="nav-item">
            <Link className="nav-link" to="https://bezla.com/contact">
              Contact
            </Link>
          </div>
        </Overlay>
        <a className="navbar-brand logo" href="https://bezla.com/">
          <img src="/logo.png" alt="Bezla Logo" />
        </a>
        <Nav>
          <LinkNav href="https://bezla.com/services" label="SERVICES" />
          <LinkNav href="https://bezla.com/products" label="PRODUCTS" />
          <LinkNav
            href="https://careers.bezla.com/jobs/Careers"
            label="CAREERS"
          />
          <LinkNav href="https://bezla.com/about" label="ABOUT" />
          <LinkNav href="https://bezla.com/team" label="TEAM" />
          <LinkNav href="https://bezla.com/blog" label="BLOG" />
          <LinkNav href="https://training.bezla.com/login" label="SIGN IN" />
          <LinkNav href="https://bezla.com/contact" label="CONTACT" />
        </Nav>
        <BurguerMenu>
          <button onClick={() => setIsMenuOpen(true)}>
            <img src="/bgr-menu.svg" alt="Menu Icon" />
          </button>
        </BurguerMenu>
      </div>
    </div>
  );
}

const LinkNav = ({ selected, label, href }) => {
  return (
    <LinkComponent
      selected={selected}
      style={{ letterSpacing: "0.071em", color: "white" }}
      href={href}
    >
      {label}
    </LinkComponent>
  );
};

const LinkComponent = styled.a`
  padding: 0.5rem;
  font-size: 1.125rem;
  margin-right: 1rem;
  letter-spacing: 0.071em;
  color: white;
  text-decoration: none;
  white-space: nowrap;
  font-family: "Open Sans", sans-serif;
  font-weight: ${(props) => (props.selected ? "700" : "400")};

  & > a {
    letterspacing: 0.071em;
    color: white;
  }
`;

const Overlay = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(22, 22, 22, 0.9);
  top: 0;
  left: 0;
  z-index: 2;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  color: #f7f7f7;

  & > div {
    width: 100%;
    padding: 0.625rem 0.625rem 0.625rem 2.8125rem;
    border-bottom: 1px solid rgba(76, 76, 76, 0.5);
    &:first-child {
      border-bottom: none;
      display: flex;
      flex-direction: row-reverse;
      align-content: flex-end;

      & > button {
        padding: 0.625rem 2.8125rem 0.625rem 2.8125rem;
        background-color: transparent;
        border-style: solid;
        border-color: transparent;
        & > img {
          width: 1.5rem;
          height: 1.5rem;
          filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(209deg)
            brightness(100%) contrast(102%);
        }
      }
    }

    & > a {
      line-height: 1.2;
      font-size: 2rem;
      color: #f7f7f7;
      text-decoration: none;
      font-family: "Open Sans", sans-serif;
    }

    & > a:hover {
      line-height: 1.2;
      font-size: 2rem;
      color: #f7f7f7;
      text-decoration: none;
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  color: white;

  & > a {
    font-weight: 400;
    padding: 0.5rem;
    margin-right: 1rem;
    font-size: 1.2rem;
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: row;

    &:last-of-type {
      margin-right: 0;
    }
  }
  @media (max-width: 991px) {
    display: none;
  }
`;

const BurguerMenu = styled.nav`
  @media (min-width: 992px) {
    display: none;
  }
  & > button {
    padding: 0.25rem 0.75rem;
    background-color: transparent;
    border-style: solid;
    border-color: transparent;
    & > img {
      width: 1.5rem;
      height: 1.5rem;
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(209deg)
        brightness(100%) contrast(102%);
    }
  }
`;
