import axios from "../utils/requests";

const frontUrl = window.location.hostname;

let BASE_URL = "";
switch (frontUrl) {
  case 'localhost':
    // BASE_URL = "http://localhost:3333";
    BASE_URL = "http://localhost:8000";
    break;
  case 'bezla-rates.vercel.app':
    BASE_URL = "https://bezla-backend-homolog.axion.company";
    break;
  case 'rate.bezla.com':
    BASE_URL = "https://rates-backend.bezla.co";
    break;
  default:
    BASE_URL = "https://rates-backend.bezla.co";
}

const getHotelsNearbyArea = async (query = "") => {
  try {
    const { data } = await axios.post(`${BASE_URL}/features/rates/areaSearch`,{ query, provider: "tripAdvisor" });
    return data || [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export default getHotelsNearbyArea;